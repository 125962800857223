<template>
  <div class="p-grid p-justify-center">
    <div class="p-col-12 p-md-6">
      <div class="card p-fluid">
        <h5>Login</h5>
        <div v-if="error" class="p-field p-grid">
          <InlineMessage class="p-md-offset-2 p-mt-2 p-mb-2" :severity="error.severity">
            {{ error.msg }}
          </InlineMessage>
        </div>
        <div class="p-field p-grid">
          <label for="username" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Gebruikersnaam</label>
          <div class="p-col-12 p-md-10">
            <InputText
                id="username"
                v-model="username"
                type="text"
                placeholder="Gebruikersnaam"
                :class="{'p-invalid': invalid.username}"
                @keyup.enter="submit"
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Wachtwoord</label>
          <div class="p-col-12 p-md-10">
            <Password
                id="password"
                v-model="password"
                :feedback="false"
                placeholder="Wachtwoord"
                :class="{'p-invalid': invalid.password}"
                @keyup.enter="submit"
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <div class="p-col-12 p-md-10 p-md-offset-2">
            <Button @click="submit" :icon="submitting ? 'pi pi-spin pi-spinner' : null" iconPos="right"
                    label="Login"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: null,
      password: null,

      submitting: false,
      invalid: {},
      error: false
    }
  },
  methods: {
    submit() {
      this.error = false

      this.invalid.username = false
      if (!this.username || this.username === '') {
        this.invalid.username = true
        return
      }

      this.invalid.password = false
      if (!this.password || this.password === '') {
        this.invalid.password = true
        return
      }

      this.submitting = true

      this.$root.userService
          .login(this.username, this.password)
          .then(response => {
            this.submitting = false
            this.$root.userService.saveUser(response.data)
            this.$root.updateOrderList()
            if (this.$route.params.to) {
              this.$router.push(this.$route.params.to)
            } else {
              this.$router.push({ name: 'dashboard' })
            }
          })
          .catch(e => {
            this.submitting = false

            if (e.response.status === 401) {
              this.error = {
                msg: 'De opgegeven gebruikersnaam/wachtwoord is incorrect.',
                severity: 'warn'
              }
              return
            }

            this.error = {
              msg: 'Er ging wat mis, probeer het later opnieuw.',
              severity: 'error'
            }
          })
    }
  }
}
</script>
